//showing processing indicator above all other items
.dataTables_processing
{
  z-index: 50
}

// button collection background becomes narrower than the buttons on ColVis plugin on Datatables
div.dt-button-collection {
  min-width: 250px;
}
