.rna-container{
  .alert {
    button.close{
      color: #000;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        margin-top: -4px;
      }
    }
  }
}

// removing the rna-container part from above as custom alert component not part of rna-container
.alert {
  button.close{
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      margin-top: -4px;
    }
  }
}