div.loader-container {
  opacity:    0.5;
  background: #000;
  width:      100%;
  height:     100%;
  z-index:    10;
  top:        0;
  left:       0;
  position:   fixed;
  display: flex;
  justify-content: flex-end;
}

// accordion (removing transition as not smooth)
.card-collapse.no-transition.collapsing{
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    -ms-transition: none;
    transition: none;
}

// date selector label
a.date-shortcut{
    font-size: $font-size-base;
    margin: 0;
    display: inline-block;
    margin-right: 12px;
    &.title{
      color: $black-color;
      font-weight: $font-weight-bold;
    }
   &.link{
      font-weight: $font-weight-normal;
      color: $dark-gray;
       &:hover{
        opacity: 0.7;
        text-decoration: underline;
        cursor: pointer;
      }
      &:active{
        opacity: 1;
        text-decoration: none;
      }
   }
}

// full screen modal
.fullscreen-modal {
  padding: 0 !important; // override inline padding-right added from js
}
.fullscreen-modal .modal-dialog {
  width: 100%;
  max-width: none;
  height: 100%;
  margin: 0;
  padding: 0;
}
.fullscreen-modal .modal-content {
    box-shadow: 0 5px 15px rgba(0,0,0,.5);
    height: auto;
    min-height: 100%;
    border-radius: 0;
}
.fullscreen-modal .modal-body {
  overflow-y: auto;
}

// disappearing navbar
@media (min-width: 992px){
.disappearing-navbar {
  display: none;
}}
